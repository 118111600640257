import React from 'react';

import { notification } from 'antd';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// @ts-ignore
import { navigate } from 'hookrouter';
import { get } from 'lodash';
import { useImmer } from 'use-immer';

export type updateTokenFn = (token: string) => Promise<void>;

export type AxiosContextType = {
  axios?: AxiosInstance;
  token: string;
  updateToken?: updateTokenFn;
};
export const AxiosContext = React.createContext<AxiosContextType>({ axios, token: '' });

function configureAxiosInstance(axiosInstance: AxiosInstance, token: string | undefined, updateToken: updateTokenFn) {
  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      // Do something before request is sent
      config = {
        baseURL: '/api',
        ...config,
      };

      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error: Error) =>
      // Do something with request error
      Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.request.responseURL.indexOf('locales') === -1) {
        // TODO handleError("generic", error)
      }

      if (get(error, 'response.status') === 401) {
        if (window.location.pathname !== '/login-form-page' && error.request.responseURL.indexOf('/me') === -1) {
          navigate('/login-form-page');

          return Promise.reject(error);
        }

        updateToken('');
      } else if (get(error, 'response.status') === 404) {
        // ignore 404
      } else {
        notification.error({
          message: 'Oops!',
          description: 'An error occured!',
        });

        return Promise.reject(error);
      }
    },
  );

  return axiosInstance;
}

export const AxiosContextProvider = (props: { token?: string; children: any }) => {
  const [state, updateState] = useImmer<{ token: string; axios: any }>({
    token: props.token || '',
    axios: configureAxiosInstance(axios.create(), props.token, updateToken),
  });

  async function updateToken(token?: string) {
    return updateState((draft) => {
      draft.token = token || '';
      draft.axios = configureAxiosInstance(axios.create(), token, updateToken);
    });
  }

  return (
    <AxiosContext.Provider value={{ axios: state.axios, token: state.token, updateToken }}>
      {props?.children}
    </AxiosContext.Provider>
  );
};
